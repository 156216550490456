import React from 'react'
import styles from './orders.module.scss';
import * as utils from "../../../utils/utils";

function Orders(props) {
    const { open, back } = props
    //const backPercent = ((back / (open + back)) * 100).toFixed(0)
    let backPercent = ((back / open ) * 100).toFixed(0)
    backPercent = (backPercent && !isNaN(backPercent))?backPercent:0
    //console.log("Order.js---")
    return (
        <div className={styles.orderContainer}>
            <div className={styles.header}>
                <div>
                <div className={styles.color}></div>
                <span className={styles.value}>{(back !== undefined)?'$'+utils.convertToInternationalCurrencySystem(back.toFixed(0)):'-'}</span>
                <div className={styles.label}>Total Backorder</div>                   
                </div>
                <div>
                    <span className={styles.label}>Total Backorder </span>
                    <span className={styles.value}>{(backPercent)?backPercent+'%':'-'}</span>
                </div>
            </div>
            <div className={styles.barProgress} >
                <div className={backPercent === 100 ? styles.myBarFull : styles.myBar} style={{ width: `${backPercent}%` }}></div>
            </div>
            <div className={styles.footer}>
            <span className={styles.label}>Total Open Order&nbsp;</span>
                    <span className={styles.value}>{(open !== undefined)?'$'+utils.convertToInternationalCurrencySystem(open.toFixed(0)):'-'}</span>
            </div>
        </div>
    )
}

export default Orders